<template>
  <section id="email-sent">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Processo"
                label-for="journey-type"
              >
                <b-form-select
                  id="journey-type"
                  v-model="filtersValues.journeyType"
                  :options="activationFlowJourneyTypesOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
              <b-form-group
                label="Operador"
                label-for="thrower"
              >
                <b-form-select
                  id="thrower"
                  v-model="filtersValues.thrower"
                  :options="throwersOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Data da operação"
                label-for="date"
              >
                <flat-pickr
                  id="date"
                  ref="datePickerDate"
                  v-model="filtersValues.date"
                  class="form-control"
                  :config="pickrConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Tipo de cadastro"
                label-for="userType"
              >
                <b-form-select
                  id="userType"
                  v-model="filtersValues.userType"
                  :options="userTypesOptions"
                  :disabled="isLoading"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="form-buttons d-flex justify-content-end"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  :disabled="isLoading"
                  variant="primary"
                  @click="getActivations()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Buscar
                </b-button>

                <b-button
                  id="btn-limpar"
                  :disabled="isLoading"
                  variant="outline-secondary"
                  @click="resetFilters()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Resultados
              </h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-table
                :busy="isLoading"
                responsive
                :items="results"
                :fields="result_fields"
                show-empty
                no-local-sorting
              >
                <template #empty>
                  <h4 class="text-center mt-2 mb-2">
                    Nenhum resultado encontrado
                  </h4>
                </template>

                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(user)>
                  <div class="text-nowrap">
                    Cadastro
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #head(action)>
                  <div class="text-nowrap text-center">
                    Escolha
                  </div>
                </template>

                <template #head(selected)>
                  <div class="text-nowrap">
                    <b-form-checkbox
                      :checked="false"
                      @change="toggleAll"
                    />
                  </div>
                </template>

                <template #cell(selected)="row">
                  <div
                    class="id-select"
                    @click.prevent="onClickSelect(row.item)"
                  >
                    <b-form-checkbox
                      v-model="row.item.selected"
                      :disabled="isSubmitting"
                    />
                  </div>
                </template>

                <template #row-details="row">
                  <div
                    class="d-flex align-items-start flex-column"
                  >
                    <div
                      v-for="(text, index) in row.item.log.text"
                      :key="`${text}-${index}`"
                    >
                      {{ text }}
                    </div>

                  </div>
                </template>

                <template #cell(action)="row">
                  <h5>
                    Sugestão:
                  </h5>
                  <div>
                    <b-badge
                      :variant="actionToColorBadge[row.item.log.action]"
                    >
                      {{ $t(`activation_review.badges.${row.item.log.action}`) }}
                    </b-badge>
                  </div>

                  <h5
                    class="mt-3"
                  >
                    O que fazer?
                  </h5>
                  <b-form-select
                    v-model="row.item.destination"
                    :disabled="isSubmitting"
                    :options="destinations"
                    class="ml-1"
                  />
                </template>

                <template #cell(user)="row">
                  <h5>
                    Data:
                  </h5>
                  {{ row.item.created_at ? formatDateTimeDbToView(row.item.created_at) : '' }}

                  <h5 class="mt-1">
                    Operador:
                  </h5>
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.thrower && row.item.thrower.avatar ? row.item.thrower.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.thrower && !row.item.thrower.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      {{ row.item.thrower ? row.item.thrower.name : '' }}<br>
                      <b-badge
                        :variant="{
                          registration_approved: 'warning',
                          registration_reviewed: 'info',
                          registration_active_reviewed: 'dark',
                          user_status_blocked: 'secondary',
                        }[row.item.type]"
                      >
                        {{ activationFlowJourneyTypesOptions.find(f => f.value === row.item.type).text }}
                      </b-badge>
                    </div>
                  </div>

                  <h5 class="mt-1">
                    Usuário:
                  </h5>
                  <div
                    class="d-flex align-items-start justify-content-start"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.user && row.item.user.avatar ? row.item.user.avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="row.item.user && !row.item.user.name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      <b-badge variant="dark">
                        ({{ row.item.user ? row.item.user.id : '' }})
                      </b-badge>
                      {{ row.item.user ? row.item.user.name : '' }}
                    </div>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap text-center">
                    <b-button
                      variant="flat-primary"
                      title="Ver log"
                      @click="row.toggleDetails"
                    >
                      <feather-icon
                        icon="FileIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>

                    <b-button
                      variant="flat-primary"
                      :to="{ name: 'cms-user-form-update', params: { id: row.item.user.id } }"
                      title="Ver destinatário"
                      target="_blank"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                    <b-button
                      variant="flat-primary"
                      :to="{ name: 'cms-user-double-registration', params: { id: row.item.user.id } }"
                      title="Ver duplos"
                      target="_blank"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row
            v-if="!isLoading"
          >
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                :link-gen="() => '#'"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div
      class="btn-scroll-to-top text-right show"
    >
      <b-button
        variant="primary"
        class="mr-1"
        :disabled="isSubmitting || selectedUsers.length === 0"
        @click="handleProcessChosen"
      >
        <b-spinner
          v-if="isSubmitting "
          small
        />
        Processar Selecionados
      </b-button>
    </div>

  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BButton,
  BFormGroup,
  BFormSelect,
  BCol,
  BSpinner,
  BBreadcrumb,
  BCard,
  BTable,
  BPaginationNav,
  BAvatar,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import JourneyService from '@/services/journeyService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  userTypes,
  activationFlowJourneyTypesOptions, managersOptions, withEmptyOptionFirst,
} from '@/utils/options'
import {
  formatDateTimeDbToView,
  // removeKnownDomains,
} from '@/utils/helpers'
import UserService from '@/services/userService'

export default {
  components: {
    BFormCheckbox,
    BBadge,
    BRow,
    BButton,
    BFormGroup,
    BFormSelect,
    BCol,
    BSpinner,
    BBreadcrumb,
    BCard,
    BTable,
    BPaginationNav,
    BAvatar,
    BCardActions,
    flatPickr,
  },
  data() {
    const defaultFilterValues = {
      userType: '',
      journeyType: '',
      date: '',
      thrower: '',
    }

    const actionToColorBadge = {
      blocked: 'danger',
      review: 'info',
      issue: 'warning',
    }

    const destinations = [
      {
        text: 'Alterar status para Revisão',
        value: 'review',
      },
      {
        text: 'Criar ticket',
        value: 'issue',
      },
      {
        text: 'Alterar status para Bloqueado',
        value: 'blocked',
      },
      {
        text: 'Nada a fazer (tira da lista)',
        value: 'nothing',
      },
      {
        text: 'Revisar manualmente (mantém na lista)',
        value: 'manual_review',
      },
    ]

    return {
      managers: [],
      isSubmitting: false,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Revisão de Ativações V2',
          active: true,
        },
      ],
      pickrConfig: {
        mode: 'range',
        allowInput: false,
        position: 'auto center',
        dateFormat: 'Y-m-d',
        altFormat: 'd/m',
        altInput: true,
        locale: Portuguese,
      },
      isLoading: false,
      userTypesOptions: withEmptyOptionFirst(userTypes),
      activationFlowJourneyTypesOptions: withEmptyOptionFirst(activationFlowJourneyTypesOptions),
      actionToColorBadge,
      defaultFilterValues,
      destinations,
      filtersValues: {
        ...defaultFilterValues,
      },
      pages: {},
      currentPage: 1,
      results: [],
      isSubmited: false,
      formatDateTimeDbToView,
      isProcessingQueue: false,

      throwersOptions: withEmptyOptionFirst(managersOptions),
    }
  },
  computed: {
    selectedUsers() {
      return this.results.filter(f => f.selected).map(m => ({
        id: m.user_id,
        name: m.name,
      }))
    },
    result_fields() {
      return [
        { key: 'selected', sortable: false, thStyle: 'width: 20px', tdClass: 'no-padding' },
        { key: 'user', sortable: false },
        { key: 'action', sortable: false },
        { key: 'actions', sortable: false, thStyle: 'width: 120px' },
      ]
    },
    resultsToCheckForDoubles() {
      return this.results.filter(f => !Number.isInteger(f.user.doublesCount))
    },
  },
  watch: {
    currentPage() {
      this.getActivations()
    },
  },
  async created() {
    this.userService = new UserService()
    this.managers = await this.userService.getAndStoreManagers()
  },
  methods: {
    handleProcessChosen() {
      const total = this.results.filter(f => f.selected).length
      this.$swal({
        title: `Processar os ${total} usuários selecionados?`,
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, processar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.postProcessChosen()
        }
      })
    },

    toggleAll(e) {
      this.results = this.results.map(m => ({
        ...m,
        selected: e,
      }))
    },

    onClickSelect(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
    },

    clearResults() {
      this.results = []
      this.isSubmited = false
      this.pages = {}
    },
    resetFilters() {
      this.filtersValues = {
        ...this.defaultFilterValues,
      }

      this.clearResults()
    },

    postProcessChosen() {
      const formData = new FormData()

      this.isSubmitting = true
      const data = this.results.filter(f => f.selected).map(m => ({
        user_id: m.user_id,
        destination: m.destination,
        doubles: m.log.doubles,
        status_reason: m.log.status_reason,
        double_user_id: m.log.double_user_id,
      }))
      formData.append('data', data)

      JourneyService.postActivationsV2(data).then(response => {
        if (response.status === 200) {
          response.data.data.forEach(() => {
            this.getActivations()
          })
        }
      }).finally(() => {
        this.isSubmitting = false
      })
    },

    getActivations() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true
      this.isSubmited = true

      const formData = { ...this.filtersValues }

      if (formData.date.includes(' até ')) {
        [formData.dateFrom, formData.dateTo] = formData.date.split(' até ')
      } else {
        formData.dateFrom = formData.date
      }

      JourneyService.listActivationsWithDoubles(formData, this.currentPage)
        .then(response => {
          if (response.data !== null) {
            const { data, ...pages } = response.data.data.activations
            this.results = data.map(m => ({
              ...m,
              _showDetails: false,
              selected: false,
              destination: m.log && m.log.action && m.log.action !== '' ? m.log.action : 'nothing',
              thrower: this.managers.find(f => f.id === m.thrower_user_id),
            }))

            if (this.results.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ops!',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: 'Nenhum resultado encontrado com os termos pesquisados',
                },
              })
            }

            this.pages = pages
          }

          this.isLoading = false
        })

      return true
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .btn-scroll-to-top {
    position: fixed;
    bottom: 5%;
    right: 80px;
    z-index: 99;

    opacity: 0;
    // transform: translateY(100px);
    transition: all .5s ease;

    &.show {
      opacity: 1;
      // transform: translateY(0)
    }
  }
</style>
