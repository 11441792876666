import useJwt from '@/auth/jwt/useJwt'

export default class JourneyService {
  static listActivations(data, page = 0) {
    return useJwt.get('/journey/activations', {
      page,
      ...data,
    })
  }

  static postActivationsV2(data) {
    return useJwt.post('/journey/activations-v2', {}, data)
  }

  static listActivationsWithDoubles(data, page = 0) {
    return useJwt.get('/journey/activations-with-doubles', {
      page,
      ...data,
    })
  }
}
